import React, { useEffect } from "react";
import Banner from "../Banner";
import { images } from "../../Assets";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import {
  calciteApplications,
  calciteOverview,
  dolomiteApplications,
  dolomiteOverview,
} from "./BlogData";

const Blogs = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])
  return (
    <>
      <div className="p-5 ">
        <Banner name="Blogs" image={images.blogs} />
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 place-items-center">
          <div class="py-4">
            <div
              class="shadow-lg group container  rounded-md bg-white  max-w-[17rem] md:max-w-sm flex justify-center items-center   mx-auto content-div"
              style={{
                background: `url(${images.dolomite})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div>
                <div class="w-full image-cover rounded-t-md">
                  <div class="p-2 m-4 w-16 h-16 text-center bg-gray-700 rounded-full text-white float-right fd-cl group-hover:opacity-25">
                    <span class="text-base tracking-wide  font-bold border-b border-white font-sans">
                      {" "}
                      10
                    </span>
                    <span class="text-xs tracking-wide font-bold uppercase block font-sans">
                      Sep.
                    </span>
                  </div>
                </div>
                <div class="py-8 px-4 bg-white  rounded-b-md fd-cl group-hover:opacity-25">
                  <span class="block text-lg text-gray-800 font-bold tracking-wide">
                    Dolomite Powder
                  </span>
                  <span class="block text-gray-600 text-sm">
                    Dolomite powder, derived from the mineral dolomite, is a
                    remarkable substance that boasts a wide array of
                    applications across numerous industries. Its unique chemical
                    composition and physical properties make it an invaluable
                    resource for various industrial processes. In this blog,
                    we'll explore the diverse uses and significance of dolomite
                    powder, shedding light on its versatility and importance.
                  </span>
                </div>
              </div>
              <div class="absolute opacity-0 fd-sh group-hover:opacity-100">
                <span class="text-xl font-bold text-white tracking-wider leading-relaxed font-sans">
                  Read More About It
                </span>
                <div class="pt-8 text-center">
                  <button
                    onClick={() =>
                      navigate("/blogs/dolomite", {
                        state: {
                          overview: dolomiteOverview,
                          data: dolomiteApplications,
                          from: "Dolomite Powder",
                        },
                      })
                    }
                    class="text-center rounded-lg p-4 bg-white  text-gray-700 font-bold text-lg"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4">
            <div
              class="shadow-lg group container  rounded-md bg-white  max-w-[17rem] md:max-w-sm flex justify-center items-center  mx-auto content-div"
              style={{
                background: `url(${images.calcite})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div>
                <div class="w-full image-cover rounded-t-md">
                  <div class="p-2 m-4 w-16 h-16 text-center bg-gray-700 rounded-full text-white float-right fd-cl group-hover:opacity-25">
                    <span class="text-base tracking-wide  font-bold border-b border-white font-sans">
                      {" "}
                      10
                    </span>
                    <span class="text-xs tracking-wide font-bold uppercase block font-sans">
                      Sep.
                    </span>
                  </div>
                </div>
                <div class="py-8 px-4 bg-white  rounded-b-md fd-cl group-hover:opacity-25">
                  <span class="block text-lg text-gray-800 font-bold tracking-wide">
                    Calcite Powder
                  </span>
                  <span class="block text-gray-600 text-sm">
                    Calcite powder, derived from the mineral calcite, is a
                    versatile and invaluable substance that finds applications
                    across various industries. Its unique properties make it a
                    sought-after material, contributing to the production of a
                    wide array of products and enhancing their quality. In this
                    blog, we will delve into the various uses of calcite powder,
                    highlighting its significance in diverse sectors.
                  </span>
                </div>
              </div>
              <div class="absolute opacity-0 fd-sh group-hover:opacity-100">
                <span class="text-xl font-bold text-white tracking-wider leading-relaxed font-sans">
                  Read More About It
                </span>
                <div class="pt-8 text-center">
                  <button
                    onClick={() =>
                      navigate("/blogs/calcite", {
                        state: {
                          overview: calciteOverview,
                          data: calciteApplications,
                          from: "Calcite Powder",
                        },
                      })
                    }
                    class="text-center rounded-lg p-4 bg-white  text-gray-700 font-bold text-lg"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
