import { images } from "../../Assets";

export const whoweare = [
  {
    image: images.bannerbg,
    type: "Indian Mineral Market",
    desc: "India's minerals market is a dynamic and thriving industry. As a crucial player in this sector, 2Microns leverages its extensive knowledge and expertise to cater to the growing demand for quality mineral products. We understand the unique requirements of the Indian market and strive to exceed expectations.",
  },
  {
    image: images.crdentials,
    type: "Our Credentials",
    desc: " With over a decade of experience in the industry, 2Microns has garnered a solid reputation for reliability, quality, and customer satisfaction. Our GST number, 08BCIPP6921J1Z6, reflects our commitment to transparency and compliance with Indian tax regulations.",
  },
  {
    image: images.parent,
    type: "Our Parent Company",
    desc: "2Microns is a subsidiary of Hitech Minerals, a conglomerate with a strong presence in the minerals industry. This affiliation enhances our resources and capabilities, ensuring that we continue to deliver excellence in every product and service",
  },
  {
    image: images.success,
    type: "Our Success",
    desc: "Driven by our unwavering commitment to quality and customer satisfaction, 2Microns has achieved significant milestones in the industry. Our annual revenue exceeds millions of dollars, a testament to our dedication to excellence and customer trust",
  },
];
export const OurReach = [
  {
    image: images.jharkhand,
    name: "Jharkhand",
    desc: "We serve the vibrant industrial landscape of Jharkhand, providing essential minerals to support various manufacturing processes and industries in the state.",
  },
  {
    image: images.tamilnadu,
    name: "Tamil Nadu",
    desc: "Our high-quality minerals are trusted by businesses in Tamil Nadu, contributing to the growth of industries such as manufacturing, agriculture, and construction.",
  },
  {
    image: images.asam,
    name: "Assam",
    desc: "In Assam, our minerals are utilized by industries ranging from agriculture to construction, ensuring the state's development and progress.",
  },
  {
    image: images.westbangal,
    name: "West Bengal",
    desc: "West Bengal benefits from our premium mineral products, which are essential components in sectors like paint, agriculture, and soap manufacturing.",
  },
  {
    image: images.karnataka,
    name: "Karnataka",
    desc: "Karnataka's industrial and agricultural sectors rely on our minerals to meet their specific needs and enhance product quality.",
  },
  {
    image: images.maharashtra,
    name: "Maharashtra",
    desc: "We supply minerals to businesses across Maharashtra, supporting various industries and contributing to the state's economic growth.",
  },
  {
    image: images.gujrat,
    name: "Gujarat",
    desc: "Gujarat's diverse industrial landscape benefits from our minerals, which are integral to sectors like paints, polymers, and ceramics.",
  },
  {
    image: images.uttarpradesh,
    name: "Uttar Pradesh",
    desc: "Our minerals play a vital role in the industrial processes of Uttar Pradesh, including the production of fertilizers, pesticides, and more.",
  },
  {
    image: images.rajasthan,
    name: "Rajasthan",
    desc: "Rajasthan, known for its rich mineral resources, also relies on our products for specific industrial applications and processes.",
  },
  {
    image: images.odisha,
    name: "Odisha",
    desc: "We cater to Odisha's growing industrial needs, providing minerals for manufacturing, construction, and other key sectors.",
  },
  {
    image: images.aandhrapradesh,
    name: "Andhra Pradesh",
    desc: "Industries in Andhra Pradesh trust our minerals to meet their production and quality requirements.",
  },
  {
    image: images.telangana,
    name: "Telangana",
    desc: "Our presence in Telangana contributes to the state's development by serving industries such as paints, agriculture, and plastics.",
  },
  {
    image: images.mp,
    name: "Madhya Pradesh",
    desc: "Our mineral products are used in various industrial applications in Madhya Pradesh, enhancing product performance and quality.",
  },
  {
    image: images.chattisgardh,
    name: "Chhattisgarh",
    desc: "Chhattisgarh's industries benefit from our minerals, which are essential in processes that demand high-quality raw materials.",
  },
  {
    image: images.punjab,
    name: "Punjab",
    desc: "Our minerals support industries in Punjab, including agriculture, textiles, and chemicals, by providing consistent and reliable raw materials",
  },
];


export const companyValues = {
    'Ethical Integrity': 'We operate with honesty and respect for all, embracing diversity and local cultures.',
    'Safety Priority': 'We prioritize the safety of our people and contractors above all else.',
    'Eco Responsibility': 'We\'re committed to sustainable practices and minimizing environmental impact.',
    'Community Engagement': 'We actively engage with and support local communities.',
    'Regulatory Compliance': 'We strictly adhere to all laws and regulations.',
    'Innovation Focus': 'We invest in advanced technology and innovation for efficiency and sustainability.',
    'Employee Development': 'We foster growth and development for our employees.',
     mission :'Responsible mineral exploration and mining for a better future.'

  };
 export const OurClients = {
    'Paint Industry': 'For the paint industry, the choice of minerals is crucial to achieving the desired color, texture, and durability. We provide high-grade minerals like calcite powder and dolomite powder, which are essential ingredients in paint formulations. Our products enhance the performance and appearance of paints, ensuring vibrant colors and long-lasting finishes.',
    'Fertilizers': 'In the field of fertilizers, the quality of raw materials is paramount. Our minerals, including dolomite powder, play a vital role in the production of fertilizers. They improve soil pH levels, enhance nutrient absorption, and contribute to healthier crop yields. We partner with fertilizer manufacturers to ensure the success of agricultural endeavors.',
    'Pesticides': 'The pesticide industry relies on precision and effectiveness. 2Microns supplies minerals that serve as carriers and inert ingredients in pesticide formulations. Our products aid in the even distribution and stability of pesticides, contributing to their efficacy and safety.',
    'Soap and Washing Powder': 'The soap and washing powder industry demands minerals that provide scouring and cleaning properties. 2Microns offers soapstone, known for its excellent heat resistance and cleaning abilities. Our soapstone is a trusted choice for manufacturing soap bars and washing powders, delivering superior cleaning performance.',
    'Pipes and Polymers': 'In the pipes and polymers sector, the quality of minerals directly impacts product strength and durability. We provide mineral chips that reinforce polymer materials, improving their mechanical properties. These mineral-filled polymers are used in various applications, including pipe manufacturing, to create robust and long-lasting products.',
    'Our Commitment to Clients': 'At 2Microns, we prioritize client satisfaction and partnership. We understand the unique needs and challenges of each industry we serve, and we work closely with our clients to provide customized solutions. Our commitment to quality, consistency, and on-time delivery has made us a preferred supplier in these diverse sectors.',
    'Join Our Growing List of Satisfied Clients': '2Microns is dedicated to continuously meeting and exceeding the expectations of our clients across the paint, fertilizers, pesticides, soap, washing powder, pipes, and polymers industries. We welcome new partnerships and look forward to serving your specific mineral needs.',
  };
  
  