import { images } from "./Assets";

export const productDetail = [
  {
    name: "dolomite powder",
    about:
      "Dolomite powder, a key player in various industries, is a finely ground form of the mineral dolomite. Its distinctive properties make it an essential ingredient in a wide array of applications, ranging from agriculture to construction. Let's delve into the fascinating world of dolomite powder, exploring its physical and chemical attributes, as well as its myriad uses.",
    image: images.dolomite,
    chemicalComposition: [
      {
        CaCO3: "51 to 56%",
        MgCO3: "42 to 45%",
        Mix_Oxides: "1 to 3%",
        SiO2: " 0.5 to 2.5%",
        LOI: " 41 to 44%",
        Mgo: "21 to 23%",
      },
    ],
    physicalComposition: [
      {
        Color:
          "Ranges from white to various shades of gray, sometimes exhibiting pink or yellow hues.",
        Texture: "Typically fine and soft, offering a smooth feel when touched",
        "Particle Size":
          "Consists of fine particles, making it easy to incorporate into various processes and products.",
        Density:
          "Moderately dense, contributing to its effectiveness in certain applications",
        Porosity:
          "Generally low porosity, allowing it to resist water absorption to some extent.",
        "Crystal Structure":
          "Crystalline structure varies, often exhibiting rhombohedral cleavage planes.",
      },
    ],
    whychoose: {},
    uses: {
      Agriculture:
        "Dolomite powder is widely used as a soil conditioner to adjust soil pH and provide essential calcium and magnesium nutrients to plants.",
      Construction:
        "It's used in the production of concrete and asphalt for road construction and building foundations.",
      Ceramics:
        "Dolomite powder acts as a fluxing agent in ceramics, enhancing the glaze's durability and color.",
      "Iron and Steel Industry":
        "It is employed as a flux in the production of iron and steel, aiding in the removal of impurities.",
      "Glass Manufacturing":
        "Dolomite powder is an important ingredient in glass production, improving glass quality and reducing melting temperatures.",
      "Paints and Coatings":
        "Used as a filler and extender in paints, dolomite powder enhances paint properties and coverage.",
      "Water Treatment":
        "Its alkalinity makes it valuable in water treatment processes, aiding in pH adjustment and mineral precipitation.",
      Detergents:
        "Dolomite powder can serve as a source of magnesium in detergent formulations.",
      Pharmaceuticals:
        "Utilized as a source of magnesium and calcium in dietary supplements.",
      "Cattle Feed Supplements":
        "Dolomite powder is added to animal feed to provide essential minerals like calcium and magnesium.",
    },
  },
  {
    name: "calcite powder",
    about:
      "Calcite powder, derived from the mineral calcite, is an exceptional gem in the world of versatile minerals. Its finely ground form showcases a fusion of scientific marvel and practical ingenuity. Let's delve into the captivating world of calcite powder and uncover its unique properties and diverse applications.",
    image: images.calcite,
    chemicalComposition: [
      {
        CaCO3: " 90% to 98%",
        MgCO3: "5.25 %",
        Silica1: "1% to 3.5%",
        SiO2: " 0.5 to 2.5%",
        LOI: " 43-44%",
        Mgo: "1.5% to 2.5%",
        Moisture: " max: 1%",
        Bulk_density: " 1.31",
      },
    ],
    physicalComposition: [
      {
        "Color and Appearance": "Fine, white crystalline powder",
        Texture: "Smooth and finely textured",
        "Crystal Structure": "Trigonal crystal system, rhombohedral formation",
        "Hardness (Mohs)": "Relatively soft (Mohs hardness of 3)",
        Density: "Typically 2.6 - 2.8 g/cm³",
      },
    ],
    whychoose: {
      "Swiss Army Knife of Minerals":
        "Its versatility spans across industries, enriching products and processes.",
      "Purity Beyond Measure":
        "High-quality, pure composition ensures optimal performance.",
      "Earth's Best Friend":
        "Sourced from natural deposits, it's an eco-friendly choice.",
      "Budget-Friendly Brilliance":
        "Enhances properties while reducing production costs.",
      "Seamless Integration":
        "Effortlessly blends into various applications, enhancing their attributes.",
    },
    uses: {
      "Construction Marvel":
        "Reinforce cement and concrete structures with enhanced strength and durability.",
      "Vivid Paint Palette":
        "Elevate the vibrancy and coverage of paints and coatings with its brilliance.",
      "Polymers' Perfect Partner":
        "Improve mechanical properties and cost-effectiveness in plastics and polymers.",
      "Paper's Partner in Shine":
        "Boost paper surface quality and printability for exceptional results.",
      "Pharmaceutical Ally":
        "Serve as a valuable calcium supplement in pharmaceutical formulations.",
      "Agricultural Asset":
        "Balance soil pH and enrich fertility for robust crop growth.",
      "Cosmetic and Personal Care":
        "Infuse cosmetics with texture and performance-enhancing properties.",
      "Ceramics' Companion":
        "Facilitate ceramic firing by acting as a fluxing agent.",
    },
  },
  {
    name: "Soap Stone",
    about:
      "Soapstone, a natural metamorphic rock, embodies a harmonious blend of aesthetic appeal and functional utility. Known for its velvety texture and understated elegance, soapstone has been cherished for centuries as a material of choice for both practical and artistic purposes. Let's delve into the captivating world of soapstone and uncover its distinctive properties.",
    image: images.soapstone,
    chemicalComposition: [
      {
        Sio2: " 60-62%",
        Al2o3: "0.25-0.28%",
        Cao: " Less Than 1 %",
        Fe2o3: " Less Than 1 %",
        LOI: "  4 to 5%",
        Mgo: "30% -33%",
      },
    ],
    physicalComposition: [
      {
        "Color and Appearance":
          "Diverse colors including gray, green, and bluish hues",
        Texture: "Silky and soap-like to the touch; easily carvable",
        "Hardness (Mohs)": "Ranges from 1 to 5.5 on the Mohs hardness scale",
        Density:
          "Varies between 2.7 to 2.8 g/cm³, dependent on mineral content",
        "Heat Retention": "Outstanding capacity for retaining heat",
        "Acid Resistance":
          "Generally resistant, though may react with strong acids",
      },
    ],
    whychoose: {
      "Elegance Redefined":
        "Elevate your space with a touch of sophistication that transcends trends.",
      "Heat's Best Friend":
        "Thrives under high temperatures, making it perfect for both functional and artistic applications.",
      "Artistic Freedom":
        "Easily shaped and sculpted to suit your creative visions.",
      Sustainability: "Sourced naturally with minimal environmental impact.",
      "Lasting Beauty": "Time-honored durability ensures its lasting allure.",
    },
    uses: {
      "Kitchen Elegance":
        "Elevate your culinary space with luxurious soapstone countertops that exude both beauty and resilience",
      "Artistic Creations":
        ":** Immerse yourself in the world of creativity with soapstone sculptures, carvings, and ornamental designs",
      "Architectural Marvels":
        "Enhance fireplaces, sinks, and bathtubs with the timeless charm of soapstone detailing",
      "Culinary Marvels":
        "From baking stones to oven liners, harness its heat retention for delectable results",
      "Industrial Ingenuity":
        "Employed in laboratories, as insulating material, and in the crafting of functional sculptures",
      "Personal Adornments":
        "Adorn yourself with unique soapstone jewelry and accessories.",
      "Educational Insights":
        "A valuable tool for educational models and geological studies.",
      "Historical Significance":
        "Revered across cultures and times for its use in artistic expression.",
    },
  },
  {
    name: "China Clay",
    about:
      "China Clay, also known as kaolin, is a naturally occurring mineral primarily composed of the clay mineral kaolinite. It is renowned for its remarkable versatility and extensive use across various industries. Its unique combination of physical and chemical properties makes it an indispensable resource for a wide range of applications.",
    image: images.chinaclay,
    chemicalComposition: [
      {
        Sio2: "  46.77%",
        Al2o3: "Illustrations 38.06%",
        Fe2o3: " 0.19%",
        Tio2: " 0.65%",
        Cao: " 0.19%",
        LOI: " 13.79%",
        Mgo: " 0.13%",
        Na2O: "0.11",
        K2O: "0.08%",
      },
    ],
    physicalComposition: [
      {
        Color:
          "Ranges from white to light gray, occasionally with a pink or green tint.",
        Texture: "Fine and soft; it has a smooth, velvety feel when touched.",
        "Particle Size":
          "Consists of tiny, platy particles that are well-suited for various industrial processes.",
        Density:
          "Relatively low density, making it easy to handle and transport.",
        Porosity:
          "Generally low porosity, resulting in low water absorption capacity.",
        "Refractive Index":
          "Exhibits a low refractive index, making it valuable in optical applications.",
      },
    ],
    whychoose: {},
    uses: {
      "Ceramics and Pottery":
        "China Clay is a cornerstone of ceramics, used to produce porcelain and other ceramic products due to its plasticity, high firing temperatures, and white color.",
      "Paper Industry":
        "It is utilized as a filler in paper production, enhancing paper's opacity, printability, and smoothness.",
      "Paint and Coatings":
        "China Clay serves as an essential extender in paint formulations, improving coverage, opacity, and rheology.",
      "Rubber Industry":
        "In rubber manufacturing, it acts as a reinforcing filler, improving mechanical properties and reducing costs.",
      Plastics:
        "China Clay is incorporated into plastic compounds to enhance rigidity, dimensional stability, and flame resistance.",
      Cosmetics:
        "Found in various cosmetics and personal care products for its absorbent properties and texture-enhancing capabilities.",
      Pharmaceuticals:
        "Used in medicinal and cosmetic preparations due to its inert nature and absorption properties.",
      Catalysts:
        "China Clay-based catalysts find application in the petrochemical industry for various chemical reactions.",
      Cement:
        "It is sometimes used as a supplementary cementitious material, enhancing concrete properties.",
      Agrochemicals:
        "China Clay acts as an inert carrier and diluent in the formulation of pesticides and fertilizers.",
    },
  },
  {
    name: "Calcium Chloride",
    about:
      "Calcium Chloride, a versatile chemical compound, is a combination of calcium and chlorine. Its distinctive properties make it an essential ingredient in various applications, ranging from de-icing roads to food processing. Let's uncover the world of calcium chloride, exploring its physical and chemical characteristics, as well as its broad array of uses.",
    image: images.calcium,
    chemicalComposition: [
      {
        "Chemical Formula": " CaCl2",
        "Molecular Weight": "110.983",
        "Density ": "2150 kg / m3",
        Appearance: " solid crystalline powder / flake / granule",
        "Melting point": "782°C",
        "Boiling point": " >1600°C",
      },
    ],
    physicalComposition: [
      {
        Form: "Typically found as a white, crystalline powder or pellets, easy to handle and dissolve.",
        Odor: "Odorless, ensuring it does not affect the sensory characteristics of products it's added to.",
        Solubility:
          "Highly soluble in water, capable of absorbing moisture from the air and forming concentrated solutions.",
        "Hygroscopic Nature":
          "Exhibits strong hygroscopicity, making it effective for dehumidification and moisture control.",
        "Melting Point":
          "Has a relatively low melting point, making it valuable for de-icing and dust suppression applications.",
        Deliquescence:
          "Under humid conditions, calcium chloride can deliquesce, forming liquid solutions on its surface.",
      },
    ],
    whychoose: {},
    uses: {
      Construction:
        "Silica sand is a key component in concrete, mortar, and asphalt, enhancing structural integrity.",
      "Glass Manufacturing":
        "It's a crucial raw material in glass production, contributing to transparency and strength.",
      "Foundry Casting":
        "Used in metal foundries for creating molds and cores due to its heat-resistant properties.",
      "Water Filtration":
        "Silica sand acts as a natural filter medium in water treatment processes, removing impurities.",
      "Oil and Gas Industry":
        "Utilized in hydraulic fracturing ('fracking') to prop open rock fractures and allow oil/gas extraction.",
      Ceramics:
        "Silica sand is used in ceramics to provide strength and shape retention during firing.",
      Electronics:
        "It's used in the production of silicon chips and photovoltaic cells for its high purity.",
      "Abrasive Blasting":
        "Silica sand's hardness makes it an effective abrasive for cleaning, polishing, and surface preparation.",
      "Sports Turf and Recreation":
        "Applied on sports fields and golf courses to improve drainage and playability.",
      "Paints and Coatings":
        "Used as a filler and extender in paints, enhancing coverage and texture.",
    },
  },
  {
    name: "silica sand",
    about:
      "Silica sand, a foundational material in numerous applications, is a granular form of quartz composed of tiny grains. Its exceptional properties make it a cornerstone of various industries, from construction to electronics. Let's explore the world of silica sand, uncovering its physical and chemical attributes, as well as its wide-ranging uses.",
    image: images.silica,
    chemicalComposition: [
      {
        " SiO2": " 70-75%",
        Na2O: "14-16%",
        CaO: "8-13%",
        Al2O3: " 1 1/2 -2",
        MgO: "2-5%",
      },
    ],
    physicalComposition: [
      {
        Color:
          "Varies from white to shades of beige or even light pink, depending on impurities.",
        "Grain Size":
          "Ranges from fine to coarse, offering versatility for different applications.",
        "Particle Shape":
          "Typically rounded or angular, impacting its suitability for specific uses.",
        Density:
          "Moderately dense, providing stability in various processes and products.",
        Porosity:
          "Generally low porosity, making it resistant to water absorption and moisture-related issues.",
        Hardness:
          "Exhibits a high level of hardness, contributing to its durability in abrasive applications.",
      },
    ],
    whychoose: {},
    uses: {
      Construction:
        "Silica sand is a key component in concrete, mortar, and asphalt, enhancing structural integrity.",
      "Glass Manufacturing":
        "It's a crucial raw material in glass production, contributing to transparency and strength.",
      "Foundry Casting":
        "Used in metal foundries for creating molds and cores due to its heat-resistant properties.",
      "Water Filtration":
        "Silica sand acts as a natural filter medium in water treatment processes, removing impurities.",
      "Oil and Gas Industry":
        "Utilized in hydraulic fracturing ('fracking') to prop open rock fractures and allow oil/gas extraction.",
      Ceramics:
        "Silica sand is used in ceramics to provide strength and shape retention during firing.",
      Electronics:
        "It's used in the production of silicon chips and photovoltaic cells for its high purity.",
      "Abrasive Blasting":
        "Silica sand's hardness makes it an effective abrasive for cleaning, polishing, and surface preparation.",
      "Sports Turf and Recreation":
        "Applied on sports fields and golf courses to improve drainage and playability.",
      "Paints and Coatings":
        "Used as a filler and extender in paints, enhancing coverage and texture.",
    },
  },
  {
    name: "tiles",
    about:
      "Tiles, exquisite pieces of craftsmanship, are thin slabs typically made from materials like ceramic, porcelain, or natural stone. Their aesthetic appeal coupled with practical functionality makes them an integral part of interior and exterior design. Discover the world of tiles, exploring their diverse uses, physical attributes, chemical properties, and more.",
    image: images.tiles,
    chemicalComposition: [
      {
        Composition:
          "Composition varies based on the tile type: ceramic tiles contain clay, while porcelain tiles are denser and have a lower water absorption rate. Natural stone tiles are carved from rock formations.",
        "Surface Resistance":
          "Tiles can resist chemicals, making them suitable for areas prone to spills and exposure to various substances.",
        "Reaction to Heat":
          "Ceramic and porcelain tiles are heat-resistant and can withstand temperature fluctuations, making them suitable for floors and walls.",
        "Reaction to Acids":
          "Some natural stone tiles are sensitive to acids, requiring proper maintenance to prevent damage.",
        "Wear and Durability":
          "Hardness varies; porcelain tiles are highly durable and scratch-resistant, while natural stone tiles may require more care.",
      },
    ],
    physicalComposition: [
      {
        "Size and Shape":
          "Available in various sizes and shapes, from traditional squares to hexagons and intricate mosaics.",
        Texture:
          "Surface textures range from glossy and smooth to matte and textured, impacting both aesthetics and grip.",
        Thickness:
          "Thickness varies depending on the tile type, affecting its durability and suitability for different spaces.",
        "Color and Pattern":
          "Tiles boast an extensive palette of colors and patterns, allowing endless design possibilities.",
        "Water Absorption":
          "Porosity varies; some tiles are highly porous, while others are nearly impermeable, influencing usage.",
      },
    ],
    whychoose: {},
    uses: {
      Flooring:
        "Tiles are a popular choice for flooring in residential, commercial, and industrial spaces due to their durability and design versatility.",
      Walls:
        "They add character and style to walls in kitchens, bathrooms, and living spaces, enhancing visual appeal.",
      Backsplashes:
        "Tiles protect walls from splashes and spills while offering an opportunity to introduce patterns and colors.",
      Bathrooms:
        "Non-slip tiles are used on bathroom floors, while moisture-resistant tiles are ideal for shower walls.",
      Kitchens:
        "Tiles offer heat resistance for kitchen floors and decorative accents for countertops and backsplashes.",
      "Outdoor Spaces":
        "Tiles suitable for outdoor use adorn patios, pool decks, and pathways, adding beauty to functional areas.",
      "Commercial Settings":
        "Tiles are used in retail spaces, restaurants, and offices to create inviting environments that withstand heavy foot traffic.",
      "Artistic Expression":
        "Mosaic tiles create intricate designs and murals, showcasing the artistic potential of tiles.",
    },
  },
  {
    name: "crazy",
    about:
      "Tiles are usually thin, square or rectangular coverings manufactured from hard-wearing material such as ceramic, stone, metal, baked clay, or even glass. They are generally fixed in place in an array to cover roofs, floors, walls, edges, or other objects such as tabletops. Alternatively, tile can sometimes refer to similar units made from lightweight materials such as perlite, wood, and mineral wool, typically used for wall and ceiling applications. In another sense, a tile is a construction tile or similar object, such as rectangular counters used in playing games (see tile-based game).",
    image: images.tiles,
    chemicalComposition: [],
  },
  {
    name: "Chips and mineral based powder",
    about:
      "Chips are small, solid fragments of minerals that are often created through mechanical processes like cutting or grinding larger mineral specimens. On the other hand, mineral-based powder refers to finely ground particles obtained from minerals. These materials are harnessed from geological deposits and play a crucial role in modern manufacturing and technological advancements.",
    image: images.mineralbased,
    chemicalComposition: [
      {
        Composition: "Comprised of various elements and compounds",
        Reactivity: "May react with acids, bases, or other substances",
        Conductivity: "Electrical conductivity varies based on composition",
        Solubility: "Different minerals display varying solubility",
      },
    ],
    whychoose: {},
    physicalComposition: [
      {
        "Particle Size": "Varies from coarse chips to fine powders",
        Color: "Range of hues based on mineral composition",
        Hardness: "Mohs scale indicates resistance to scratching",
        Luster: "Appearance of light reflection (e.g., metallic or glassy)",
        Density: "Mass per unit volume",
      },
    ],
    uses: {
      "Paints and Coatings":
        "Mineral chips like calcite or talc are added to paint formulations to improve the paint's texture, durability, and coverage. They also enhance color retention and help achieve desired finishes.",
      "Plastics and Polymers":
        "Mineral fillers are used in the plastic and polymer industry to improve the mechanical properties of materials. They can enhance stiffness, strength, and dimensional stability, making plastics suitable for various applications.",
      Ceramics:
        "In the ceramic industry, mineral chips like kaolin and feldspar are used to improve the properties of ceramic products. They contribute to better firing characteristics, whiteness, and strength.",
      "Rubber Products":
        "Mineral fillers are incorporated into rubber formulations to enhance abrasion resistance, improve dimensional stability, and reduce production costs.",
      "Construction Materials":
        "Mineral chips are used in construction materials such as concrete and mortar to improve workability, reduce shrinkage, and enhance strength and durability.",
      Agriculture:
        "Some mineral fillers are used as soil conditioners to improve soil structure and nutrient retention, contributing to healthier plant growth.",
      "Paper and Pulp Industry":
        "Mineral fillers like calcium carbonate are used in the paper industry to improve paper brightness, opacity, and printability.",
      "Adhesives and Sealants":
        "Mineral fillers can enhance the adhesive properties of glues and sealants, improving bonding strength and flexibility.",
      Pharmaceuticals:
        "In pharmaceuticals, mineral fillers can be used as excipients in tablet formulations to ensure uniform tablet hardness and aid in drug delivery.",
      "Food Industry":
        "Certain food products may use mineral fillers as anticaking agents or as dietary supplements, providing essential minerals to consumers.",
      "Personal Care Products":
        "Mineral fillers can be found in cosmetics and personal care products, contributing to their texture and performance.",
      "Environmental Applications":
        "Mineral chips may be used in wastewater treatment processes to aid in the removal of impurities and pollutants.",
      Textiles:
        "In the textile industry, mineral fillers can be added to fabrics and textiles to enhance properties like flame resistance, durability, and wrinkle resistance.",
      "Oil and Gas Drilling":
        "In drilling fluids, mineral fillers can help control fluid density and viscosity, aiding in drilling operations.",
      Metallurgy:
        "Some mineral chips are used in metallurgy for their role in fluxes, aiding in the removal of impurities during metal smelting and refining processes.",
    },
  },
];
