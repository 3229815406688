import React, { useEffect } from "react";
import Banner from "../Banner";
import { images } from "../../Assets";
import Footer from "../Footer/Footer";
import { OurClients, OurReach, companyValues, whoweare } from "./whoWeAre";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
const AboutUS = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Number of slides to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="p-5 ">
        <Banner name="About Us" />
        <div className="grid mt-14 grid-cols-1 md:grid-cols-2 place-items-center">
          <div className="flex flex-wrap  justify-center gap-8 items-center">
            <div className="border-2 shadow shadow-gray-300 border-gray-400  flex flex-col justify-center items-center p-10">
              <p className="text-4xl font-bold">10+</p>
              <p className="text-2xl font-semibold">YEARS EXPERIENCE</p>
            </div>
            <div className="leading-8">
              <p className="text-primary">INTRODCTION OF US</p>
              <p className="font-extrabold text-xl md:text-2xl">
                2-MICRONS MINERALS
              </p>
              <p className="font-extrabold text-xl md:text-2xl">& CHEMICALS</p>
            </div>
          </div>
          <div className="w-full md:w-3/6 text-[15px] leading-7 mt-4 md:mt-0 text-justify text-gray-500">
            <p>
              2Microns is a leading player in the minerals industry,
              specializing in the extraction, processing, and distribution of
              premium-grade minerals. With over a decade of experience, we have
              established ourselves as a reliable source for a wide range of
              mineral products, including dolomite powder, calcite powder,
              mineral chips, soapstone, and china clay.
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-center font-bold text-4xl mt-8 font-serif">
            Who We Are
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-6 place-items-center">
            {whoweare.map((i, index) => {
              return (
                <div
                  key={index}
                  className="w-full h-[450px] overflow-y-scroll  shadow shadow-gray-400 p-6"
                >
                  <img src={i.image} className="h-1/2 w-full" alt={i.type} />
                  <p className="font-semibold mt-4">{i.type}</p>
                  <p className="text-sm text-justify leading-6">{i.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="slider-container mt-10">
          <h1 className="text-center font-bold text-4xl mt-12 mb-8 font-serif">
            Our Reach
          </h1>
          <Slider {...settings}>
            {OurReach.map((i) => {
              return (
                <div className="imageSlides   ">
                  <img
                    src={i.image}
                    alt={i.name}
                    className="w-24 h-24 rounded-full"
                  />
                  <p className="font-semibold font-sans">{i.name}</p>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className=" mt-24 shadow-lg rounded-md shadow-gray-300 p-8 gap-4 grid grid-cols-1 lg:grid-cols-2 place-items-start">
          <div className="p-4 order-2">
            {Object.keys(companyValues).map((i, index) => {
              return (
                <div>
                  <h1 className="text-[16px] text-yellow-500 font-bold">{i}</h1>
                  <p className="text-justify text-sm md:text-[15px]  md:leading-2 w-full lg:w-4/5">
                    {companyValues[i]}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="w-full h-80 md:h-96 order-1 md:order-2">
            <img src={images.policy} alt="about" className="w-full h-full" />
          </div>
        </div>
        <div className="slider-container mt-10 mb-10">
          <h1 className="text-center font-bold text-4xl mt-12 mb-12 font-serif">
            Company Statistics
          </h1>
          <div className="flex justify-around items-center gap-4 flex-wrap cursor-pointer">
            <div className="text-center hover:scale-x-110 transition-all shadow-sm shadow-gray-600 p-10 w-[300px] ">
              <h1 className="font-bold text-xl">Total Employees</h1>
              <p className="text-sm font-semibold">11 to 35</p>
            </div>{" "}
            <div className="text-center hover:scale-x-110 transition-all shadow-sm shadow-gray-600 p-10 w-[300px] ">
              <h1 className="font-bold text-xl">Nature of Business</h1>
              <p className="text-sm font-semibold">Manufacturer</p>
            </div>
            <div className="text-center hover:scale-x-110 transition-all shadow-sm shadow-gray-600 p-10 w-[300px]">
              <h1 className="font-bold text-xl">Year of Establishment</h1>
              <p className="text-sm font-semibold">2012</p>
            </div>
            <div className="text-center hover:scale-x-110 transition-all shadow-sm shadow-gray-600 p-10 w-[300px]">
              <h1 className="font-bold text-xl">Annual Turnover</h1>
              <p className="text-sm font-semibold">10 to 12 Crore</p>
            </div>
            <div className="text-center hover:scale-x-110 transition-all shadow-sm shadow-gray-600 p-10 w-[300px]">
              <h1 className="font-bold text-xl">GST Number</h1>
              <p className="text-sm font-semibold">08BCIPP6921J1Z6</p>
            </div>
          </div>
        </div>
        <div className="slider-container mt-10 mb-10">
          <h1 className="text-center font-bold text-4xl mt-12 mb-12 font-serif">
            Our clients
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-4  cursor-pointer">
            {Object.keys(OurClients).map((i,index)=>{
              return (
                <div key={index} className="text-start  transition-all rounded-md hover:scale-x-95 shadow-sm shadow-gray-600 p-10 w-full">
                <h1 className="font-bold text-xl text-yellow-500">{i}</h1>
                <p className="text-sm font-medium text-gray-500 leading-7">{OurClients[i]}</p>
              </div>
              )
            })}
           
           
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUS;
