import React from "react";
import { Carousel } from "react-responsive-carousel";
import { images } from "../../Assets";

const Carousal = () => {
  return (
    <>
      <Carousel  infiniteLoop autoPlay stopOnHover={true} showThumbs={false} showStatus={false} className="mt-20 carousel-wrapper">
        <div>
          <img alt="carousal" src="https://cdn.corporatefinanceinstitute.com/assets/mining-industry.jpeg" />
        </div>
        <div>
          <img alt="carousal" src="https://www.here.com/sites/g/files/odxslz256/files/2022-06/mining-truck-mine-blog.jpg" />
        </div>
        {/* <div>
          <img alt="carousal" src={images.tiles} />
        </div> */}
        {/* <div>
          <img alt="carousal" src={images.carousal2} />
        </div> */}
        <div>
          <img alt="carousal" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTXLtFKtgfrybOYMA3bGdMoqySnS-E4ky1Iw&usqp=CAU" />
        </div>
        <div>
          <img alt="carousal" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY9YiPKsN3nTb1D9wN2k95CyecVdATm9CsJg&usqp=CAU" />
        </div>
    
        <div>
          <img alt="carousal" src="https://img2.exportersindia.com/product_images/bc-full/2018/7/5767706/natural-silica-sand-1531385888-4094498.jpeg" />
        </div>
        {/* <div>
          <img alt="carousal" src={images.carousal7} />
        </div> */}
        {/* <div>
          <img alt="carousal" src={images.carousal8} />
        </div> */}
        {/* <div>
          <img alt="carousal" src={images.carousal9}  height={"200px"}/>
        </div> */}
        <div>
          <img alt="carousal" src="https://www.mining.com/wp-content/uploads/2016/03/dredge-pit-open-truck-machinery-equipment-loader-coal-admin-900.jpg" />
        </div>
      </Carousel>
    </>
  );
};

export default Carousal;
