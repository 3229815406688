export const dolomiteApplications = {
  "Construction Industry":
    "Dolomite powder plays a pivotal role in the construction sector. It is used as an essential ingredient in the production of construction materials such as concrete and mortar. The addition of dolomite powder enhances the workability and durability of these materials. It also aids in reducing shrinkage and improving the overall strength of structures.",
  "Steel Industry":
    "The steel industry relies on dolomite powder for its excellent refractory properties. Dolomite acts as a refractory material and is used in the lining of furnaces and converters. Its high melting point and resistance to heat make it an ideal choice for this critical application.",
  Agriculture:
    "Dolomite powder is widely used in agriculture to address soil acidity issues. It serves as an excellent source of both calcium and magnesium, essential nutrients for plant growth. By amending acidic soils with dolomite, farmers can improve soil pH, nutrient availability, and overall crop productivity.",
  "Glass Industry":
    "Dolomite powder finds application in the glass industry as a fluxing agent. It lowers the melting point of silica, making it easier to form glass at lower temperatures. This results in energy savings and improved glass quality.",
  "Water Treatment":
    "Dolomite powder is utilized in water treatment processes to adjust the pH levels of water. It acts as a neutralizing agent, helping to balance the acidity or alkalinity of water. This is crucial for efficient water purification and the removal of impurities.",
  "Paint Industry":
    "Dolomite powder is a valuable additive in the paint industry. Its fine particle size and high brightness contribute to the improvement of paint texture and opacity. It also enhances the paint's resistance to weathering, ensuring long-lasting color and durability.",
  "Ceramics Industry":
    "Dolomite powder is used in ceramics for its ability to enhance the firing characteristics of clay. It aids in achieving desired shapes, sizes, and surface finishes in ceramic products.",
  "Plastics and Polymers":
    "Dolomite powder is incorporated into plastic and polymer formulations to improve mechanical properties such as stiffness and impact resistance. It enhances the dimensional stability of plastic materials, making them suitable for various applications.",
  Pharmaceuticals:
    "Dolomite powder is used in pharmaceuticals as a source of magnesium and calcium in dietary supplements and antacids. It aids in maintaining proper mineral balance in the body.",
  "Desulfurization Processes":
    "Dolomite powder is employed in flue gas desulfurization systems to remove sulfur dioxide from industrial emissions. It reacts with sulfur dioxide to form calcium sulfate, a byproduct that can be safely disposed of.",
};
export const dolomiteOverview = {
  overView:
    "Dolomite powder, derived from the mineral dolomite, is a remarkable substance that boasts a wide array of applications across numerous industries. Its unique chemical composition and physical properties make it an invaluable resource for various industrial processes. In this blog, we'll explore the diverse uses and significance of dolomite powder, shedding light on its versatility and importance.",
  conclusion:
    "Dolomite powder's versatility and wide range of applications make it an essential mineral in various industries. From enhancing the durability of construction materials to aiding in soil improvement in agriculture, dolomite powder continues to play a significant role in modern industrial processes. Its unique properties and diverse utility make it a mineral marvel that underpins many aspects of our daily lives.",
};
export const calciteApplications = {
    'Paint Industry': 'Calcite powder is an essential component in the paint industry. Its fine particle size and excellent dispersibility make it an ideal filler material. When incorporated into paint formulations, it improves texture, enhances opacity, and contributes to the paint\'s durability. Calcite powder also aids in achieving desired color shades and reduces the amount of expensive pigments needed.',
    'Plastics and Polymers': 'The plastic and polymer industry benefits greatly from calcite powder. It is used as a reinforcing filler in plastics to improve dimensional stability, stiffness, and impact resistance. Calcite-filled plastics find applications in diverse products, from automotive parts to household items, where increased strength and durability are essential.',
    'Paper Industry': 'Calcite powder is used in the paper industry as a coating pigment and filler. Its exceptional brightness and opacity enhance the appearance and printability of paper. Additionally, calcite\'s ability to improve paper\'s surface properties leads to increased ink holdout and reduced ink penetration, resulting in sharper and more vibrant prints.',
    'Construction Materials': 'Calcite powder is an integral component in the construction industry. It is used in the production of cement, mortar, and concrete to improve workability, reduce shrinkage, and enhance strength. The inclusion of calcite powder also aids in achieving desired surface finishes in construction projects.',
    'Agriculture': 'Agricultural practices benefit from calcite powder\'s soil conditioning properties. When added to soil, it enhances soil structure, reduces acidity, and improves nutrient retention. This leads to healthier plant growth, increased crop yields, and improved overall soil quality.',
    'Pharmaceuticals': 'Calcite powder serves as an essential excipient in pharmaceutical tablet formulations. Its properties ensure uniform tablet hardness, aiding in drug delivery. Additionally, it acts as an antacid and calcium supplement in various pharmaceutical applications.',
    'Environmental Applications': 'Calcite powder plays a role in environmental applications, particularly in water treatment processes. It can be used to adjust the pH of water, aiding in the removal of impurities and pollutants.',
    'Metallurgy': 'Calcite powder is used as a flux in metallurgical processes. It assists in the removal of impurities during the smelting and refining of metals, contributing to the production of high-quality alloys.',
    'Adhesives and Sealants': 'The adhesive and sealant industry benefits from the inclusion of calcite powder, which enhances bonding strength and flexibility in various adhesive formulations.',
    'Personal Care Products': 'Calcite powder can be found in cosmetics and personal care products, contributing to their texture and performance.',
  };
  

  
export const calciteOverview = {
    overView:"Calcite powder, derived from the mineral calcite, is a versatile and invaluable substance that finds applications across various industries. Its unique properties make it a sought-after material, contributing to the production of a wide array of products and enhancing their quality. In this blog, we will delve into the various uses of calcite powder, highlighting its significance in diverse sectors.",
    conclusion:"Calcite powder's multifaceted properties and applications make it an indispensable material in numerous industries. From enhancing the visual appeal of paints to reinforcing plastics and improving soil quality in agriculture, calcite powder plays a vital role in diverse sectors. Its versatility and beneficial attributes continue to drive innovation and improvement in various industries, making it a valuable resource in today's world."
  };