import React, { useEffect } from "react";
import Banner from "../Banner";
import { images } from "../../Assets";
import Footer from "../Footer/Footer";
import { dolomiteApplications } from "./BlogData";
import { useLocation, useNavigate } from "react-router-dom";

const IndividulaBlog = () => {
  const location = useLocation();
  const { data, from, overview } = location.state || {};
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="p-5 ">
        <Banner name="Blogs" image={images.blogs} />
        {location.state?<div className="mx-0 md:mx-36 mt-12">
          <p className="font-semibold text-xl text-yellow-500">
            What is {from}
          </p>
          <p className="text-justify leading-8 text-gray-500 text-lg">
            {overview.overView}
          </p>
          <div className="mt-10 shadow-lg shadow-gray-600 p-10">
            {Object.keys(data).map((i, index) => {
              return (
                <div key={index}>
                  <p className="font-semibold text-xl text-yellow-500">{i}:</p>
                  <p className="leading-8 text-justify"> {data[i]}</p>
                </div>
              );
            })}
          </div>
          <p className="font-semibold text-xl mt-10 text-yellow-500">
            Conclusion
          </p>
          <p className="text-justify leading-8 text-gray-500 text-lg">
            {overview.conclusion}
          </p>
        </div>:""}
      </div>
      <Footer />
    </>
  );
};

export default IndividulaBlog;
