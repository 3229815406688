import dolomite from "./dolomite.jpg";
import contactbg from "./contactus.jpg";
import blogs from "./Blogs.avif";
import calcium from "./calcium-chloride.jpg";
import calcite from "./calcite.jpg";
import chinaclay from "./china-clay.jpg";
import soapstone from "./soap-stone.jpg";
import bannerbg from "./bannerbg.jpg";
import aboutus from "./about-us-hero.jpg";
import aboutusside from "./aboutus.jpg";
import policy from "./policy.avif";
import techquark from "./WhatsApp Image 2023-07-06 at 4.57.30 PM.jpeg";
import silica from "./silica-quartz-sand.jpg";
import tiles from "./Tiles-Design.png";
import mineralbased from "./black-granite-chips-powder.jpg";
import whychoose from "./whychoose.png";
import crdentials from "./crde.jpg";
import parent from "./Parent-Company.jpg";
import success from "./success.jpg";
import jharkhand from "./jharkhand.jpg";
import tamilnadu from "./tamilnadu.jpg";
import asam from "./assam.webp";
import westbangal from "./westbangal.webp";
import karnataka from "./karnataka.jpg";
import maharashtra from "./maharashtra.jpg";
import gujrat from "./gujrat.jpg";
import uttarpradesh from "./up.webp";
import rajasthan from "./rajasthan.jpg";
import odisha from "./odisha.webp";
import aandhrapradesh from "./aandhrapradesh.png";
import telangana from "./telangana.jpg";
import mp from "./mp.webp";
import chattisgardh from "./chattisgarh.jpg";
import punjab from "./punjab.jpg";
import carousal1 from "./carousal1.jpeg"
import carousal2 from "./carousal2.jpeg"
import carousal3 from "./carousal3.jpeg"
import carousal4 from "./carousal4.jpeg"
import carousal5 from "./carousal5.jpeg"
import carousal6 from "./carousal6.jpeg"
import carousal7 from "./carousal7.jpeg"
import carousal8 from "./carousal8.jpeg"
import carousal9 from "./carousal9.jpeg"

export const images = {
    carousal1,carousal2,carousal3,carousal4,carousal5,carousal6,carousal7,carousal8,carousal9,
  jharkhand,
  tamilnadu,
  asam,
  westbangal,
  karnataka,
  maharashtra,
  rajasthan,
  punjab,
  gujrat,
  uttarpradesh,
  mp,
  odisha,
  aandhrapradesh,
  telangana,
  chattisgardh,
  dolomite,
  contactbg,
  blogs,
  success,
  parent,
  aboutus,
  chinaclay,
  soapstone,
  calcite,
  calcium,
  bannerbg,
  mineralbased,
  aboutusside,
  policy,
  techquark,
  silica,
  tiles,
  whychoose,
  crdentials,
};
